import eventsHandler from '@/services/common/eventsHandler.js'

class taxForm extends eventsHandler {
  constructor() {
    super()
    this._tax = {}
    this._valid = false
  }

  get tax() {
    const tax = {
      ...this._tax
    }
    return tax
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._tax.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetTax() {
    this._tax = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {tax: {}})
  }

  updateField(key, value) {
    this._tax[key] = value
    this._executeCallbacksOf('update', {tax: this._tax})
    this._executeCallbacksOf('update-' + key, {key: this._tax[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(tax) {
    this._tax = tax
    this._executeCallbacksOf('update', {tax: this._tax})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new taxForm()