<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1"
      >Generale</v-subheader
    >
    <v-row no-gutters class="py-1">
      <v-col>
        <v-text-field
          v-model="description"
          :rules="[max255CharRule]"
          dense
          filled
          label="Descrizione"
          hide-details="auto"
          @input="updateField('description', $event)"

        ></v-text-field>
        <v-col>
        <v-text-field
          v-model="value"
          :rules="[presenceRuleWith0]"
          dense
          filled
          type="number"
          label="Valore"
          hide-details="auto"
          @input="updateField('value', $event)"

        ></v-text-field>
      </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import taxForm from "@/services/taxes/tax.form.js";

export default {
  name: "GeneralFormTab",
  components: {},
  data: function () {
    return {
      description: undefined,
      value: undefined,
      loadingTax: false,
      formValid: false,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRuleWith0: (v) => {
        return (!!v || v === 0) || "Il campo è obbligatorio";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function () {
    this.handleObjectChanges(taxForm.tax);

    var self = this;
    taxForm.on("update", function (data) {
      self.handleObjectChanges(data.tax);
    });
  },
  methods: {
     fields() {
      return ['description', 'value']
    },
    handleObjectChanges(tax) {
      const fields = this.fields()
      const newValKeys = Object.keys(tax)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && tax[field] != this[field]) {
          this[field] = tax[field]
        }
      }
    },
    updateField(key, value) {
      taxForm.updateField(key, value);
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      taxForm.setValid(newVal);
    },
  },
};
</script>